import $ from "jquery";
import { SpaRequest } from "./spa";
import { data_loss_ajax_navigation_check, data_loss_check } from "./data_loss";
import getSlug from "speakingurl";

// Allows you to determine which button was pressed to send the ajax request
// As an example if two submit buttons are on the same page
$(document).on("click", ".js-change-form-action-and-submit", function(e) {
  // prevent default sending
  e.preventDefault();
  const self = $(this);

  const $form = self.closest("form");
  const new_action = self.data("action");

  // submit
  ajax_submit($form, new_action);
});

$(document).on("change", ".ajax-form.ajax-form-change", function(e) {
  // prevent default sending
  e.preventDefault();
  const self = $(this);
  const ok = handleCustomConfirm(self);
  if (ok === true) {
    let action = self.data("change-action");
    let target = self.data("change-target");
    const data = self.serialize();
    if (data_loss_check() === true) {
      // reset parsley remote cache
      window.Parsley._remoteCache = {};
      const request = new SpaRequest(action, data);
      request.send(response => {
        $(target).text(response.getContent());
      });
    }
  }
});

// Sends a ajax request to the server with the form's data as the payload
// Creates a custom confirm if requested
// A custom confirm is a browser popup which shows a list of predefined
//  messages which ask the user if he is sure he wants to submit the data
$(document).on("submit", ".ajax-form", function(e) {
  // prevent default sending
  e.preventDefault();
  const self = $(this);

  self.find('[data-friendly-url="true"]').each((i, e) => {
    const friendly_url = $(e);
    friendly_url.data("allowed-to-change", false);
    const value = friendly_url.val();
    const clean = getSlug(value);
    friendly_url.val(clean);
  });

  const ok = handleCustomConfirm(self);

  if (ok === true) {
    ajax_submit(self);
  }
});

// Loads parts of pages and displays them
// Uses ajax to give the user a smoother experience when navigating through pages
//  because we can avoid full document page loads
// The data attributes get sent with the ajax request
$(document).on("click", ".ajax-link", function(event) {
  // Disable normal a tag navigation
  event.preventDefault();
  const self = $(this);

  let href = self.attr("href");
  if (
    typeof href === typeof undefined ||
    href === "" ||
    href === "javascript:void(0)"
  ) {
    href = self.attr("data-href");
  }

  const data = self.data();

  const ok = handleCustomConfirm(self);

  const clicked_element = event.currentTarget;
  const clicked_element_jquery = $(clicked_element);

  const callback = clicked_element_jquery.attr("data-callback");
  const callback_name = clicked_element_jquery.attr("data-cb");
  const callback_accepted_name = clicked_element_jquery.attr("data-accept-cb");

  if (ok === true) {
    if (data_loss_ajax_navigation_check(event)) {
      // The navigation got accepted
      // Destroy the tinyMCE's in the form
      window.tinymce.EditorManager.editors = [];

      const callback_accepted_reference = window[callback_accepted_name];
      if (typeof callback_accepted_reference === "function") {
        callback_accepted_reference(clicked_element);
      }

      const request = new SpaRequest(href, data);
      request.send(function(response) {
        handleResponse(data, response);

        const callback_reference = window[callback_name];
        if (typeof callback_reference === "function") {
          callback_reference(clicked_element);
        }

        // Get the passed trough callback function
        const func = eval(callback);
        // To handle the function reference
        if (typeof func === "function") {
          func();
        }
        // Init data tables
        window.initDt();
      });
    }
  }
});

function ajax_submit(element, new_action) {
  // get data
  if (new_action === undefined) {
    new_action = "";
  }
  let action = element.attr("action");
  if (typeof action === typeof undefined || action === false || action === "") {
    action = element.data("action");
  }

  if (new_action !== "") {
    action = new_action;
  }

  const meta = element.data();
  const data = element.serialize();

  // validate form
  let abort = false;
  if (element.hasClass("validate")) {
    let valid;
    var $inputs = $("input, select, textarea, radio, checkbox", element).not(
      ".no-validate"
    );
    $inputs.each(function() {
      const self = $(this);
      if (
        self.is(":visible") ||
        self.attr("data-parsley-validate-hidden") === "true"
      ) {
        valid = self.parsley().validate();
        window.Parsley.setLocale($("html").attr("lang"));
        if (valid !== true) {
          abort = true;
        }
      }
    });
  }
  if (abort) {
    return false;
  }

  if (data_loss_check() === true) {
    // reset parsley remote cache
    window.Parsley._remoteCache = {};

    const request = new SpaRequest(action, data);
    request.send(function(response) {
      handleResponse(meta, response);

      // get the passed trough callback funtion
      const callback = $(element).data("callback");

      const func = eval(callback);
      //to handle the function reference
      if (typeof func === "function") {
        func();
      }
    });
  }
}

export function handleResponse(meta, response) {
  if (response.isRedirect()) {
    window.location.replace(response.getContent());
    return;
  }

  if (response.isJson()) {
    return;
  }

  if (response.isCallbackList()) {
    loadCallbackListResponse(meta, response.getContent());
    return;
  }

  if (response.isHtml()) {
    loadHtmlResponse(meta, response.getContent());
  }
}

function loadCallbackListResponse(data, json_content) {
  let revived_data;
  if (typeof json_content !== typeof {}) {
    revived_data = JSON.parse(json_content);
  } else {
    revived_data = json_content;
  }

  for (let i in revived_data) {
    const func = eval(`callback${i}`);
    if (typeof func === "function") {
      func(revived_data[i]);
    }
  }
}

function loadHtmlResponse(data, html) {
  // if the container ist not set or not empty
  if (typeof data.container !== typeof undefined && data.container !== false) {
    // if the html should be load in a modal
    if (data.container === "modal-lg" || data.container === "modal") {
      // little modal
      let id = "#global_modal";
      if (data.container === "modal-lg") {
        // big modal
        id = id + "_lg";
      }

      const $modal = $(document).find(id);

      let modal_title = "";
      if (
        typeof data.modal_title !== typeof undefined &&
        data.modal_title !== false &&
        data.modal_title !== ""
      ) {
        modal_title = data.modal_title;
      }
      $modal.find(".modal-header .modal-title").html(modal_title);
      $modal.find(".modal-body").html(html);

      $modal.modal("toggle");
    } else {
      const replace = data.replace;
      const data_container = $(document).find(data.container);

      if (replace === "container") {

        // Save old active tab in any edit newsletter content type modal
        var active_tab_class = '.nav-link.text-dark.active';
        var active_tab_href = $('.nav-item').find(active_tab_class).attr('href');

        // Replace data_container element with new html
        data_container.replaceWith(html);

        // Is undefined on edit newsletter content type modal
        if(active_tab_href !== undefined) {
          // After html replace there is usual 1 active which can be the wrong one if tab is inside html replace
          var active_after_html_replace = $('.nav-item').find(active_tab_class);
          var active_after_html_replace_href = active_after_html_replace.attr('href');
          if(active_after_html_replace_href !== active_tab_href) {
            var active_tab_content_class = "active show";
            active_after_html_replace.removeClass("active");
            $('.tab-content.bg-white.p-3').find(active_after_html_replace_href).removeClass(active_tab_content_class);
            $(active_tab_href).addClass(active_tab_content_class);
            document.querySelectorAll(`a[href='${active_tab_href}']`)[0].classList.add("active");
          }
          // Else nothing must be changed, because correct tab is still active
        }

      } else if (replace === "append") {
        // Opposite of prepend(), append() method appends an element to the end of the list
        data_container.append(html);
      } else if (replace === "plop-into") {
        // Change the content of data_container element
        data_container.html(html);
      } else {
        data_container.html(html);
      }
    }
  }
}
