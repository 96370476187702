import $ from "jquery";

function possibleChangeEvent() {
  $("form").attr("changed", "true");
}

function formSubmit() {
  // The submit button was clicked
  $("form").attr("changed", "false");
}

function secure_and_changed(form) {
  const changed = form.attr("changed");

  if (
    typeof changed === typeof undefined ||
    changed === false ||
    changed === "false"
  ) {
    // No changes were made to the form
    //  we can safely navigate away
    return false;
  }

  if (!form.hasClass("secure")) {
    return false;
  }

  if (changed === "true") {
    return true;
  } else {
    throw "Something wiard happened";
  }
}

/**
 * @param navigation_event
 * @return {boolean}
 */
function data_loss_ajax_navigation_check(navigation_event) {
  if (navigation_event === undefined) {
    throw "I got an undefined event";
  }

  const target = $(navigation_event.target);

  // If the element itself or a parent has the attribute
  if (
    target.hasClass("pass-by-secure") === true ||
    target.parents(".pass-by-secure").length > 0
  ) {
    // The navigation element does not lose data
    //  or its ok to lose the data
    return true;
  }

  const form = $("form");

  if (secure_and_changed(form)) {
    if (confirm("Wollen Sie Das Formular Verlassen ohne zu Speichern?")) {
      // Yes
      form.attr("changed", "false");
      return true;
    } else {
      // No
      return false;
    }
  }
  // The form is not secured or has not been changed
  return true;
}

$(document).on("click", 'a[href^="http"]', function(event) {
  const self = $(this);

  if (self.hasClass("ajax-link")) {
    // Ajax links are already covered over the ajax_forms check
    return;
  }

  const form = $(document).find("form");

  if (secure_and_changed(form)) {
    if (confirm("Wollen Sie Das Formular Verlassen ohne zu Speichern?")) {
      form.attr("changed", "false");
    } else {
      event.preventDefault();
    }
  }
});

/**
 * Gets called when the form gets submitted
 *
 * Returns true if the request can continue.
 *
 * @return {boolean}
 */
function data_loss_check() {
  $("form").attr("changed", "false");
  return true;
}

export {
  possibleChangeEvent,
  formSubmit,
  data_loss_check,
  data_loss_ajax_navigation_check
};

/**********************************************************************************************************************
 * add something to Hide and show Modal event
 * */
$(document).on("hide.bs.modal", "#global_modal", function(e) {
  /**
   * Detect actions in window and save specific actions in the recent event
   * also detect if the Form is changed
   * */
  $(document).on(
    "change",
    "form input :not(.datepicker-input), " +
      "form textarea, " +
      "form select, " +
      "form," +
      "form .mce-edit-area, " +
      "form #tinymce",
    () => {
      $("form").attr("changed", "true");
    }
  );

  $(document).on("click", 'form [type="submit"]', function(evt) {
    $("form").attr("changed", "false");
  });

  var $a = $(this);
  var $ele = $(document).find('form[changed="true"]');
  if ($ele.length && $("form").attr("secure") !== "false") {
    if (!confirm("Wollen Sie Das Formular Verlassen ohne zu Speichern?")) {
      $ele.attr("changed", "true");
      e.preventDefault();
      e.stopImmediatePropagation();
      return false;
    } else {
      $ele.attr("changed", "false");
      $a.click();
    }
  }
});

$(document).on("hide.bs.modal", "#global_modal_lg", function(e) {
  /**
   * Detect actions in window and save specific actions in the recent event
   * also detect if the Form is changed
   * */
  const $a = $(this);
  const $ele = $(document).find('form[changed="true"]');
  $(document).on(
    "change",
    "form, " +
      "form input, " +
      "form textarea, " +
      "form select, " +
      "form iframe, " +
      "form .mce-edit-area, " +
      "form #tinymce",
    () => {
      $("form").attr("changed", "true");
    }
  );

  $(document).on("click", 'form [type="submit"]', function(evt) {
    $("form").attr("changed", "false");
  });
  if ($ele.length && $("form").attr("secure") !== "false") {
    if (!confirm("Wollen Sie Das Formular Verlassen ohne zu Speichern?")) {
      $ele.attr("changed", "true");
      e.preventDefault();
      e.stopImmediatePropagation();
      return false;
    } else {
      $ele.attr("changed", "false");
      $a.click();
    }
  }
});

/**
 * Detect actions in window and save specific actions in the recent event
 * also detect if the Form is changed
 * */
$(document).on(
  "change",
  "form," + "form input," + "form textarea," + "form select," + "form iframe",
  function(event) {
    possibleChangeEvent(event);
  }
);

$(document).on("click", 'form [type="submit"]', function(event) {
  formSubmit(event);
});
