import $ from "jquery";
import { default as detect } from "browser-detect";

/**
 * @param {string} name
 * @param {string} version_string
 */
function isSupported(name, version_string) {
  const version = parseInt(version_string);
  if (name === "chrome" && version >= 45) {
    return true;
  }
  if (name === "firefox" && version >= 38) {
    return true;
  }
  if (name === "edge" && version >= 12) {
    return true;
  }
  if (name === "ie" && version >= 11) {
    return true;
  }
  if (name === "ios" && version >= 9) {
    return true;
  }
  if (name === "safari" && version >= 9) {
    return true;
  }
  if (name === "android" && version >= 5) {
    return true;
  }
  if (name === "opera" && version >= 30) {
    return true;
  }
  return false;
}

$(document).ready(function() {
  // const { name, version } = detect();
  // if (isSupported(name, version)) {
    $("#only-chrome-is-supported").addClass("d-none");
    $(".only-chrome-is-supported-reversed").removeClass("d-none");
  /* } else {
    window.location.href = document.getElementById("browser_not_supported").value;
  } */
});
