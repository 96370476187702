import $ from "jquery";

/**
 * @param {string} url The url for the request
 * @param data This data will be transmitted over post
 * @constructor
 */
class SpaRequest {
  /**
   * @param {string} url
   * @param {*} [data]
   */
  constructor(url, data = undefined) {
    this._url = url;
    this._data = data;
  }

  /**
   * @callback SpaRequestSuccessCB
   * @param SpaResponse
   */
  /**
   * @param {SpaRequestSuccessCB} success_callback
   * @return XMLHttpRequest
   */
  send(success_callback) {
    return $.ajax({
      type: "post",
      url: this._url,
      data: this._data,
      statusCode: {
        // forbidden
        403() {
          // Redirect to login
          window.location.replace("/entry/");
        },
        500(response_data) {
          document.open("text/html", "replace");
          document.write(response_data.responseText);
          document.close();
        }
      },
      beforeSend() {},
      success(response_data) {
        if (typeof response_data === typeof "") {
          response_data = response_data.trim();
          if (response_data !== "") {
            response_data = JSON.parse(response_data);
          }
        }
        const response = new SpaResponse(response_data);
        success_callback(response);
      },
      error() {}
    });
  }
}

class SpaResponse {
  /**
   * @param {{ type: any; content: any; }} response_data
   */
  constructor(response_data) {
    this._type = response_data.type;
    this._content = response_data.content;
  }

  isHtml() {
    return this._type === "html";
  }

  isJson() {
    return this._type === "json";
  }

  isCallbackList() {
    return this._type === "callback-list";
  }

  isRedirect() {
    return this._type === "redirect";
  }

  getContent() {
    return this._content;
  }
}

export { SpaRequest, SpaResponse };
