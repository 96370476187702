import $ from "jquery";

window.showGeneralSuccessAlert = function() {
  var $ele = $(document).find("#general_success_alert");

  $ele.show("fast");
  setTimeout(function() {
    $ele.slideUp(500);
  }, 2500);
};

window.callbackAlertList = function(elements) {
  var $alert_box = $(document).find("#general_alert");
  // the template is located in #general_alert
  // remove the class js-template otherwise it will be display none
  var $template = $alert_box.find(".js-template");
  var timeout = 0;

  $.each(elements, function() {
    var $clone = $template.clone();
    var background = "bg-";

    // if the type ist not exactly the same as the bootstrap class
    if (this.type === "error") {
      background = background + "danger";
    } else {
      background = background + this.type;
    }
    $clone.addClass(background);

    $clone.html(this.message);
    // makes the element visible
    $clone.removeClass("js-template");

    $clone.appendTo($alert_box);

    // because nice effect :)
    setTimeout(function() {
      $clone.slideUp(500);
    }, 8500 + timeout);
    timeout = timeout + 550;
  });
};
