import $ from "jquery";

$(document).ready(function() {
  /**
   * Change Bootstrap Failure that modal is not stackable forever
   * so heres the solution
   * */
  var modal = $(".modal");
  modal.on("show.bs.modal", function() {
    var idx = $(".modal:visible").length;
    $(this).css("z-index", 1040 + 10 * idx);
  });
  modal.on("shown.bs.modal", function() {
    var idx = $(".modal:visible").length - 1; // raise backdrop after animation.
    var modal_backdrop = $(".modal-backdrop").not(".stacked");
    modal_backdrop.css("z-index", 1039 + 10 * idx);
    modal_backdrop.addClass("stacked");
  });
  $(document).on('hidden.bs.modal', '.modal', function () {
    // After general modal hide a modal is still show keep modal-open in body
    $('.modal:visible').length && $(document.body).addClass('modal-open').css("padding-right","32px");
  });
});
