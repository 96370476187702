import $ from "jquery";

$(document).ready(() => {
  if ($("#newsletter-register").length > 0) {
    $("#person").hide();
    $("#cooperation").hide();
    $(
      "#" +
        $(".radio-type.active")
          .children()
          .val()
    ).show();
    $(".radio-type").on("click", function() {
      $("#person").hide();
      $("#cooperation").hide();
      $(
        "#" +
          $(this)
            .children()
            .val()
      ).show();
    });
  }
});
