import $ from "jquery";
import { SpaRequest } from "../common/spa";

window.deleteRowOfRecipientTable = function deleteRowOfRecipientTable(
  element_class
) {
  $(element_class).hide("slow");
};

window.reloadPage = function reloadPage(url) {
  if (url) {
    location.reload(url);
  } else {
    location.reload();
  }
};

window.removeEle = function removeEle(ele) {
  $(document)
    .find(ele)
    .replaceWith("");
};

window.closeModal = function closeModal() {
  $(".modal").modal("hide");
  $(".modal-lg").modal("hide");
};

window.reloadContentBlocks = function reloadContentBlocks() {
  const $ele = $(document).find(".js-reload");
  $ele.click(() => {});
  $ele.click();
};

window.manageContentReload = function() {
  window.reloadContentBlocks();
  window.showGeneralSuccessAlert();
  window.closeModal();
};

window.imageFormDelete = function imageFormDelete(relatedTarget) {
  const button = $(relatedTarget);
  const recipient = button.data("path-target");
  const wrapper = button.closest(".file-manager-wrapper");
  wrapper.find(".image-form-preview").attr("src", "");
  wrapper.find(".js-hide-empty").hide();
  $(document)
    .find(recipient)
    .attr("value", "");
  $(relatedTarget).attr("value", "");
  $("#temp_image_container").attr("value", "");
};
window.videoFormDelete = function videoFormDelete(relatedTarget) {
  const button = $(relatedTarget);
  const recipient = button.data("path-target");
  const wrapper = button.closest(".file-manager-wrapper");
  wrapper.find(".video-form-preview").attr("src", "");
  wrapper.find(".js-hide-empty").hide();
  $(document)
    .find(recipient)
    .attr("value", "");
  $(relatedTarget).attr("value", "");
};

window.reloadElementSelector = function(element_selector) {
  const element = $(document).find(element_selector);
  const callback = $(element).data("callback");

  let open = [];

  for (let key of window.nested_set_openly.keys()) {
    open.push(key);
  }

  const request = new SpaRequest(element.data("resource-reload"), {
    open: JSON.stringify(open)
  });
  request.send(function(response) {
    if (response.isHtml()) {
      var content = response.getContent();
      element.replaceWith(content);
    }

    // Get the passed trough callback function
    var func = eval(callback);
    // To handle the function reference
    if (typeof func == "function") {
      func();
    }
  });
};
window.documentFormDelete = function documentFormDelete(relatedTarget) {
  const button = $(relatedTarget);
  const recipient = button.data("path-target");
  const form = button.parents("form:first");
  form.find(".document-form-preview").attr("href", "");
  form.find(".document-form-preview").addClass("d-none");
  $(document)
    .find(recipient)
    .attr("value", "");
  $(relatedTarget).attr("value", "");
};

window.redirect = function redirect(url) {
  url = url || window.event;
  window.location.replace(url);
};

window.historyPreservingRedirect = function(url) {
  url = url || window.event;
  window.location.href = url;
};

window.toggleContentBlockOnOff = function toggleContentBlockOnOff(element) {
  $(document)
    .find(element)
    .toggleClass("online");
};

window.file_manager_type = "";

window.changeFilemanagerType = function(type) {
  if (window.file_manager_type !== type) {
    replaceParam("#fancybox-frame", "type", type);
    window.file_manager_type = type;
  }
};

window.reinitDatepicker = function reinitDatepicker() {
  $(document).ready(function() {
    setTimeout(function() {
      $(document)
        .find('[data-toggle="datepicker"]')
        .datepicker({
          zIndex: 5100,
          language: "de-DE"
        });
    }, 600);
  });
};
