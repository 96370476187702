import $ from "jquery";

$(document).on("click", "[data-confirm]:not(.ajax-link)", function(e) {
  if (window.recursive !== undefined && window.recursive === true) {
    window.recursive = false; // reset flag
    return true; // let the event bubble away
  }

  e.stopPropagation();

  var ok = handleCustomConfirm($(this));

  if (ok) {
    window.recursive = true;
    $(this).click();
  } else {
    e.preventDefault();
  }
});

window.handleCustomConfirm = function(element) {
  var messages = element.data("confirm");

  var ok = false;
  if (messages !== "" && messages !== undefined) {
    // check if is an array
    // you can give more than one message, make sure its a valid json array
    // example: [&quot;Lorem Ipsum? the first&quot;,&quot;Ipsum Lorem? the second&quot;]
    if ($.isArray(messages)) {
      $.each(messages, function() {
        ok = custom_confirm(this);
        if (ok === false) {
          return false;
        }
      });
    } else {
      ok = custom_confirm(messages);
    }
  } else {
    // if there is no confirm message or data-attribute we will give true back
    ok = true;
  }

  return ok;
};

window.custom_confirm = function(message) {
  return confirm(message);
};
