import $ from "jquery";

$(document).ready(function() {
  // Popup on slider
  $(document)
    .find(".custom-popup")
    .each(function() {
      // the containers for all your galleries
      $(this).magnificPopup({
        delegate: "a.gallery-popup-img", // the selector for gallery
        type: "image", // this is default type
        gallery: {
          enabled: true
        },
        image: {
          titleSrc: function({ el: element }) {
            return element.find("img").attr("title");
          }
        }
      });
    });

  $("a[data-slide-dots]").on("click", function() {
    $(this).addClass("gallery-active-element");
  });

  let currSlide = null;

  $(".custom-gallery").each((index, gallery_element) => {
    const gallery = $(gallery_element);
    const gallery_parent = gallery.parent();
    const gallery_grand_parent = gallery_parent.parent();

    gallery.on("swipe slide init click change", function(event, slick) {
      currSlide = slick.currentSlide + 1;
      let dots = gallery_grand_parent.find("a[data-slide-dots]");
      $(dots).each(function() {
        gallery.removeClass("gallery-active-element");
      });
      gallery_grand_parent
        .find("a[data-slide-dots]")
        .removeClass("gallery-active-element");
      gallery_grand_parent
        .find(`a[data-slide-dots]:nth-child(${currSlide})`)
        .addClass("gallery-active-element");
      $(".mfp-title").html(
        $(gallery_grand_parent)
          .find(".content-wrap")
          .attr("title")
      );
    });

    // common slick options
    let options = {
      infinite: false,
      arrows: false,
      adaptiveHeight: true,
      slidesToScroll: 1,
      slidesToShow: 1,
      focusOnSelect: false,
      responsive: [
        {
          breakpoint: 576
        },
        {
          breakpoint: 768
        },
        {
          breakpoint: 992
        },
        {
          breakpoint: 1200
        }
      ]
    };

    // init slick
    gallery.slick(options);
    // Custom dots nav
    gallery_grand_parent.find("a[data-slide-dots]").click(function(e) {
      e.preventDefault();
      $("a[data-slide-dots]").removeClass("gallery-active-element");
      const slide_dots = $(this).data("slide-dots");
      gallery.slick("slickGoTo", slide_dots - 1);
    });

    gallery_parent.find(".gallery-thumbnails > div").click(function() {
      const thumbnail = $(this);
      gallery.slick("slickGoTo", thumbnail.index());
    });
  });
});
