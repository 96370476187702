import $ from "jquery";

import "jquery-ui";

import "parsleyjs";

import "./common/spa";

import "bootstrap/dist/js/bootstrap";
import "./common/bootstrap_fixes";

import "slick-carousel";

import "./main/gallery";

import "magnific-popup";

import "./admin/ajax-callbacks";

import "./main/login";
// ajax forms
import "./common/messages";
import "./common/confirm_messages";
import "./common/ajax_forms";

import "./main/unsupported";
import "./common/onerror";

// scroll top

/**
 * $ + jQuery Global Variable Declaration
 * */
window.$ = $;
window.jQuery = $;
