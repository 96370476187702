// From meyer start
window.imgErrorGallerySomeOtherOtherCase = function(img) {
  const in_preparation = $("#in_preparation").val();
  $(img)
    .parent()
    .append(
      "<div" +
        ' class="product-img-placeholder' +
        ' product-image-preview w-100 text-center" style="' +
        "    height: 100%;\n" +
        "    margin-top: -66.6%;\n" +
        "    display: flex;\n" +
        "    justify-content: center;\n" +
        "    flex-direction: column;\n" +
        '    "><i' +
        ' class="icon-camera d-block" style="font-size: 50px; margin-bottom: -15px;"></i><span style="font-size: 17px;"><strong>' +
        in_preparation +
        "</strong></span></div>"
    );
  $(img).remove();
  return true;
};

window.imgErrorGallerySomeOtherCase = function(img) {
  $(img)
    .parent()
    .append(
      "<div" +
        ' class="product-img-placeholder' +
        ' product-image-preview w-100 text-center" style="/*min-height:' +
        " 200px;*/    margin-top: -70%;\n" +
        "    padding-bottom: 40px;\n" +
        '    padding-top: 40px;"><i' +
        ' class="icon-camera d-block" style="font-size: 23px; margin-bottom: -15px;"></i></div>'
    );
  $(img).remove();
  return true;
};

window.imgErrorDefault = function imgErrorDefault(img) {
  const in_preparation = $("#in_preparation").val();
  $(img)
    .parent()
    .append(
      "<div" +
        ' class="product-img-placeholder' +
        ' product-image-preview w-100 text-center"><i' +
        ' class="icon-camera d-block" style="font-size: 50px; margin-bottom: -15px;"></i><span style="font-size: 17px;"><strong>' +
        in_preparation +
        "</strong></span></div>"
    );
  $(img).remove();
  return true;
};

window.imgErrorGallery = function imgErrorGallery(img) {
  const in_preparation = $("#in_preparation").val();
  $(img)
    .parent()
    .append(
      "<div" +
        ' class="product-img-placeholder' +
        ' product-image-preview w-100 text-center" style="/*min-height:' +
        " 200px;*/    margin-top: -64%;\n" +
        "    padding-bottom: 40px;\n" +
        '    padding-top: 40px;"><i' +
        ' class="icon-camera d-block" style="font-size: 50px; margin-bottom: -15px;"></i><span style="font-size: 17px;"><strong>' +
        in_preparation +
        "</strong></span></div>"
    );
  $(img).remove();
  return true;
};
// From meyer end

// Used for content type image previews like newsletter general settings logo
window.replaceBrokenImagePlaceholder = function(img) {
  $(img).attr("src", "");
  img.onerror = null;
  return true;
};
